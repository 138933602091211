import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews } from '../../components';

import css from './ListingPage.module.css';
import moment from 'moment';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, listing } = props;

  if(!reviews?.length && !listing?.attributes?.publicData?.review) return null

  const customReview = {
    createdAt: moment(listing?.attributes?.publicData?.reviewDate)?.format("YYYY-MM-DD"),
    rating: listing?.attributes?.publicData?.review,
    displayName: listing?.attributes?.publicData?.reviewBy,
    reviewContent: listing?.attributes?.publicData?.reviewContent
  }
  return (
    <section className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={reviews} customReview={customReview} listing={listing} />
    </section>
  );
};

export default SectionReviews;
